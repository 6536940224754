import React from 'react';
import styles from  './how.component.module.scss';
import camera from '../../camera.png';
import future from '../../future.png';
import test from '../../test.png';
const HowItWorks: React.FC = () => (
  <div className={styles['how-it-works-section']}>
    <h2>How the Quiz Works</h2>
    <div className={styles['how-it-works']}>
      <div className={styles['step']}>
        <h3>Record</h3>
        <img src={camera} alt="Camera" />
        <p>Film yourself shooting from various angles to capture your technique.</p>
      </div>
      <div className={styles['step']}>
        <h3>Assessment</h3>
        <img src={test} alt="Test" />
        <p>Answer quiz questions based on your shooting videos.</p>
      </div>
      <div className={styles['step']}>
        <h3>Improve</h3>
        <img src={future} alt="Future" />
        <p>Receive personalized feedback and drills to enhance your shooting skills.</p>
      </div>
    </div>
  </div>
);

export default HowItWorks;
