// src/store/authSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  sessionToken: string | null;
  fullName: string;
  email: string;
}

const initialState: AuthState = {
  sessionToken: localStorage.getItem('session_token'),
  fullName: '',
  email: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionToken(state, action: PayloadAction<string>) {
      state.sessionToken = action.payload;
      localStorage.setItem('session_token', action.payload);
    },
    clearSessionToken(state) {
      state.sessionToken = null;
      localStorage.removeItem('session_token');
    },
    setUserInfo(state, action: PayloadAction<{ fullName: string; email: string }>) {
      state.fullName = action.payload.fullName;
      state.email = action.payload.email;
    },
  },
});

export const { setSessionToken, clearSessionToken, setUserInfo } = authSlice.actions;
export default authSlice.reducer;
