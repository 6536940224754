// src/components/ShotRecordingReminder.tsx

import React, { useState, useEffect } from 'react';
import styles from './reminder.module.scss';
import 'animate.css';

const ShotRecordingReminder: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    const hasAcknowledged = localStorage.getItem('hasAcknowledgedShotRecording');
    if (!hasAcknowledged) {
      setIsVisible(true);
    }
  }, []);

  const handleAcknowledge = () => {
    setIsAnimatingOut(true);
    localStorage.setItem('hasAcknowledgedShotRecording', 'true');
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // Duration matches the animation duration
  };

  if (!isVisible) return null;

  return (
    <div
      className={`${styles.popover} ${
        isAnimatingOut ? 'animate__animated animate__fadeOutUp' : 'animate__animated animate__fadeInDown'
      }`}
    >
      <p>Have you recorded 12 shots from the front and side?</p>
      <button onClick={handleAcknowledge}>Yes, I've recorded them</button>
    </div>
  );
};

export default ShotRecordingReminder;
