import { TestResult } from '../pages/results/GetResults';
import api from './api.service';

export const getTestQuestions = async () => {
  const response = await api.get('/quiz/questions');
  return response.data;
};
export const getTestResults = async (testSessionId: string) => {
  const response = await api.get(`/quiz/test-results/${testSessionId}`);
  return response.data as TestResult;
};

export const createTestSession = async () => {
  const response = await api.post('/quiz/test-sessions');
  return response.data;
};

export const submitAnswers = async (testSessionId: string, answers: any[]) => {
  const response = await api.post(`/quiz/test-sessions/${testSessionId}/answers`, { answers });
  return response.data;
};
