// src/components/OrientationOverlay.tsx

import React from 'react';
import './OrientationOverlay.scss';

interface OrientationOverlayProps {
  isVisible: boolean;
}

const OrientationOverlay: React.FC<OrientationOverlayProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="orientation-overlay">
      <div className="message">
        {/* <img src="rotate-icon.png" alt="Rotate Device" /> */}
        <p>Please rotate your device back to portrait mode for the best experience.</p>
      </div>
    </div>
  );
};

export default OrientationOverlay;
