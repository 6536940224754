// src/store/store.ts

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth.slice';
import testSessionReducer from './test.session.slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    testSession: testSessionReducer,
    // Add other reducers here
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
