// src/components/Question.tsx

import React from "react";
import styles from "./questions.component.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

interface QuestionProps {
  question: {
    id: number;
    demo_url?: string;
    question_text: string;
    category: string;
    question_type: string;
    options: null | string[];
  };
  answer: string | undefined;
  onChange: (questionId: number, value: string) => void;
  handleNext: () => void;
  handlePrev: () => void;
  isLast: boolean;
  onOpenVideo: (demoUrl: string) => void; // Add this prop
}

const Question: React.FC<QuestionProps> = ({
  question,
  answer,
  onChange,
  handleNext,
  handlePrev,
  isLast,
  onOpenVideo, // Destructure the new prop
}) => {
  const renderInput = () => {
    const questionId = question.id;
    const name = `question_${questionId}`;

    switch (question.question_type) {
      case "Yes/No":
        return (
          <div className={styles.optionsContainer}>
            <label>
              <input
                type="radio"
                name={name}
                value="Y"
                checked={answer === "Y"}
                onChange={() => onChange(questionId, "Y")}
                required
              />
              <span>Yes</span>
            </label>
            <label>
              <input
                type="radio"
                name={name}
                value="N"
                checked={answer === "N"}
                onChange={() => onChange(questionId, "N")}
              />
              <span>No</span>
            </label>
          </div>
        );
      case "Multiple Choice":
        return (
          <div className={styles.optionsContainer}>
            {question.options &&
              question.options.map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name={name}
                    value={option}
                    checked={answer === option}
                    onChange={() => onChange(questionId, option)}
                    required
                  />
                  <span>{option}</span>
                </label>
              ))}
          </div>
        );
      case "Open-Ended":
        return (
          <textarea
            name={name}
            value={answer || ""}
            onChange={(e) => onChange(questionId, e.target.value)}
            required
          ></textarea>
        );
      default:
        return null;
    }
  };

  const handleVideoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (question.demo_url) {
      onOpenVideo(question.demo_url);
    }
  };

  return (
    <div
      className={styles.question}
      data-type={question.question_type.toLowerCase().replace(" ", "-")}
    >
      <h2>
        {question.question_text}{" "}
        {question.demo_url ? (
          <button
            className={styles.videoButton}
            onClick={handleVideoClick}
            aria-label="Watch demo video"
          >
            <FontAwesomeIcon icon={faVideo} /><span>Play Video Here</span>
          </button>
        ) : null}
      </h2>
      <div className={styles.answers} data-id={`question-${question.id}`}>
        {renderInput()}
      </div>
      <div className={styles.navigation}>
        <button type="button" onClick={handlePrev} disabled={isLast}>
          &#10094;
        </button>
        <button type="button" onClick={handleNext} disabled={!answer || isLast}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default Question;
