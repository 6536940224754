// src/pages/TakeQuiz.tsx

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./quiz.page.module.scss";
import { getTestQuestions, submitAnswers } from "../../services/quiz.service";
import Header from "../homepage/components/header/header.component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Question from "./components/questions/questions.component";
import Modal from "./components/modal/modal";
import ShotRecordingReminder from "./components/reminder/reminder.component";
import { Helmet } from "react-helmet";

interface IQuestion {
  id: number;
  question_text: string;
  category: string;
  question_type: string;
  options: null | string[];
  question_order: number;
  demo_url?: string;
}

const TakeQuiz: React.FC = () => {
  const { testSessionId: routeTestSessionId } = useParams<{
    testSessionId: string;
  }>();
  const testSessionId =
    useSelector((state: RootState) => state.testSession.testSessionId) ||
    routeTestSessionId;
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [error, setError] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const navigate = useNavigate();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const data = await getTestQuestions();
        const typedData = data as { questions: IQuestion[] };
        setQuestions(typedData.questions);
      } catch (err) {
        setError("Failed to load questions.");
      }
    };

    fetchQuestions();
  }, []);

  const isQuestionAnswered = (questionId: number) => {
    return answers[questionId] && answers[questionId].trim().length > 0;
  };

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    // Prevent going to the next slide if the current question is not answered
    if (newIndex > oldIndex && !isQuestionAnswered(questions[oldIndex].id)) {
      setCurrentSlide(oldIndex); // Stay on the current slide
    } else {
      setCurrentSlide(newIndex);
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleChange = (questionId: number, value: string) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const handleOpenVideo = (demoUrl: string) => {
    setCurrentVideoUrl(demoUrl);
    setIsModalOpen(true);
  };

  const handleCloseVideo = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl(null);
  };

  const allQuestionsAnswered =
    questions.length > 0 && questions.every((q) => isQuestionAnswered(q.id));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handleBeforeChange,
    arrows: false,
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formattedAnswers = Object.keys(answers).map((questionId) => ({
        question_id: parseInt(questionId),
        answer_text: answers[parseInt(questionId)],
      }));
      if (testSessionId) {
        await submitAnswers(testSessionId, formattedAnswers);
        // Redirect to results page or display a confirmation
        navigate(`/quiz-results/${testSessionId}`);
      } else {
        setError("Test session ID is missing.");
      }
    } catch (err) {
      setError("Failed to submit answers.");
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (questions.length === 0) {
    return <p>Loading questions...</p>;
  }

  return (
    <>
      <Helmet>
        <title>Shooters Shoot | Shot Assesment</title>
        <meta
          name="description"
          content="Take the quiz to analyze your basketball shooting style and get tips to improve your shot."
        />
        <meta
          name="keywords"
          content="basketball, shooting style, improve basketball shot, shooting quiz"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph for Social Media Sharing */}
        <meta
          property="og:title"
          content="Shooters Shoot - Analyze Your Basketball Shot"
        />
        <meta
          property="og:description"
          content="Analyze your basketball shooting style with our custom quiz and get personalized tips to improve your performance."
        />
        <meta
          property="og:image"
          content="https://yourdomain.com/og-image.png"
        />
        <meta property="og:url" content="https://www.changingthegamefoundation.org/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Shooters Shoot - Improve Your Shot"
        />
        <meta
          name="twitter:description"
          content="Take our quiz to analyze your basketball shooting style and improve your game."
        />
        <meta
          name="twitter:image"
          content="https://yourdomain.com/twitter-image.png"
        />

        {/* Canonical Link for SEO */}
        <link rel="canonical" href="https://www.changingthegamefoundation.org/" />
      </Helmet>
      <div className={styles.container}>
        {/* Header Component */}
        <Header />
        <ShotRecordingReminder />
        <form className={styles.slider} onSubmit={handleSubmit}>
          {/* Progress Indicator */}
          <div className={styles["active-slide"]}>
            <p>
              {currentSlide + 1}/{questions.length}
            </p>
          </div>

          <div className={styles["questions"]}>
            <Slider {...settings} ref={sliderRef}>
              {questions.map((question, index) => (
                <Question
                  key={question.id}
                  question={question}
                  answer={answers[question.id]}
                  onChange={handleChange}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  isLast={index === questions.length - 1}
                  onOpenVideo={handleOpenVideo} // Pass the function to Question component
                />
              ))}
            </Slider>
          </div>
          {/* Submit Button */}
          {allQuestionsAnswered && (
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit Answers"}
            </button>
          )}
        </form>
        {isLoading && <div className={styles.loader}>Loading...</div>}

        {/* Modal Component */}
        <Modal isOpen={isModalOpen} onClose={handleCloseVideo}>
          {currentVideoUrl && (
            <div className={styles.videoContainer}>
              <video controls autoPlay className={styles.videoPlayer}>
                <source src={currentVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default TakeQuiz;
