import api from './api.service';

export interface EnterEmailResponse {
  session: {
    access_token: string;
    token_type: string;
    expires_in: number;
    expires_at: number;
    refresh_token: string;
  };
}
console.log({ process: process.env.REACT_APP_API_BASE_URL })
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.shotbreakdown.basketball/api';

export const enterEmail = async (email: string, fullName: string): Promise<EnterEmailResponse> => {
  const response = await api.post<EnterEmailResponse>(`${API_BASE_URL}/user/enter-email`, { email, name: fullName });
  return response.data;
};
