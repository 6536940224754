// src/components/ProtectedRoute.tsx

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const sessionToken = useSelector((state: RootState) => state.auth.sessionToken);
  const location = useLocation();

  if (!sessionToken) {
    // Redirect to the home page or login page
    return (<Navigate to="/" state={{ from: location }} replace />);
}

return children;
};

export default ProtectedRoute;
