import React from 'react';
import styles from  './header.component.module.scss';
import logo from './logo.png';
const Header: React.FC = () => (
  <header className={styles.header}>
    <img src={logo} alt="Logo" className="logo" />
    <h1>Shot Breakdown Guide</h1>
    <p>You're about to have a HUGE breakthrough in understanding your shot.</p>
  </header>
);

export default Header;
