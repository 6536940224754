import React from 'react';
import styles from  './feature.component.module.scss';

const FeaturesSection: React.FC = () => (
  <div className={styles['features-section']}>
    <div className={styles['feature']}>
      <h2 className={styles['section-title']}>🧐 Why You Want to Know your Shooting Type</h2>
      <p><strong>✅ Identify Strengths and Weaknesses:</strong> Understanding your shooting type helps pinpoint areas for improvement.</p>
      <p><strong>✅ Enhance Performance:</strong> Knowledge of your shooting mechanics can lead to better accuracy and consistency.</p>
      <p><strong>✅ Personalized Training:</strong> Tailored drills and techniques can accelerate skill development.</p>
      <p><strong>✅ Competitive Edge:</strong> Gaining insights that others may not have can provide an advantage on the court.</p>
      <p><strong>✅ Confidence Boost:</strong> Knowing your shooting type can increase a player's confidence in their abilities.</p>
    </div>
    <div className={styles['feature']}>
      <h2 className={styles['section-title']}>📈 How the Quiz Helps You Become Better</h2>
      <p><strong>✅ Comprehensive Analysis:</strong> The quiz breaks down every aspect of the shooting process.</p>
      <p><strong>✅ Self-Assessment:</strong> Encourages users to actively engage in self-evaluation.</p>
      <p><strong>✅ Actionable Feedback:</strong> Provides specific areas to focus on during practice.</p>
      <p><strong>✅ Access to Expertise:</strong> Offers options to get professional coaching and drills.</p>
      <p><strong>✅ Convenient and Accessible:</strong> Users can take the quiz and get results anytime, anywhere.</p>
    </div>
  </div>
);

export default FeaturesSection;
