import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GetResults from './pages/results/GetResults';
import ProtectedRoute from './components/ProtectedRoute';
import './app.module.scss';
import HomePage from './pages/homepage/homepage.page';
import OrientationOverlay from './components/OrientationOverlay';
import TakeQuiz from './pages/quiz/quiz.page';

const App: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const updateOrientation = () => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    setShowOverlay(isLandscape && isMobile);
  };

  useEffect(() => {
    updateOrientation(); // Initial check
    window.addEventListener('resize', updateOrientation);
    window.addEventListener('orientationchange', updateOrientation);

    return () => {
      window.removeEventListener('resize', updateOrientation);
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);
  return (
  <>
    <OrientationOverlay isVisible={showOverlay} />
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<HomePage />} />
        {/* Protected Routes */}
        <Route
          path="/take-test/:testSessionId"
          element={
            <ProtectedRoute>
              <TakeQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quiz-results/:testSessionId"
          element={
            <GetResults />
          }
        />
        {/* Add a catch-all route or other routes as needed */}
      </Routes>
    </Router>
  </>
  );
};

export default App;
