

import styles from "./homepage.module.scss";
import Header from "./components/header/header.component";
import EnterEmailForm from "./components/cta/email.form.component";
import FeaturesSection from "./components/feature/feature.component";
import HowItWorks from "./components/howitworks/how.component";
import { Helmet } from "react-helmet";

const HomePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Shooters Shoot | Improve Your Basketball Shot</title>
        <meta
          name="description"
          content="Take the quiz to analyze your basketball shooting style and get tips to improve your shot."
        />
        <meta
          name="keywords"
          content="basketball, shooting style, improve basketball shot, shooting quiz"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph for Social Media Sharing */}
        <meta
          property="og:title"
          content="Shooters Shoot - Analyze Your Basketball Shot"
        />
        <meta
          property="og:description"
          content="Analyze your basketball shooting style with our custom quiz and get personalized tips to improve your performance."
        />
        <meta
          property="og:image"
          content="https://yourdomain.com/og-image.png"
        />
        <meta property="og:url" content="https://www.changingthegamefoundation.org/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Shooters Shoot - Improve Your Shot"
        />
        <meta
          name="twitter:description"
          content="Take our quiz to analyze your basketball shooting style and improve your game."
        />
        <meta
          name="twitter:image"
          content="https://yourdomain.com/twitter-image.png"
        />

        {/* Canonical Link for SEO */}
        <link rel="canonical" href="https://www.changingthegamefoundation.org/" />
      </Helmet>
      <div className={styles["container"]}>
        <Header />
        <HowItWorks />
        <EnterEmailForm />
        <p className={styles["intro-text"]}>
          Discover your unique shooting type and get personalized insights to
          elevate your game.
        </p>
        <FeaturesSection />
      </div>
    </>
  );
};

export default HomePage;
