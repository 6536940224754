// src/store/testSessionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TestSessionState {
  testSessionId: string | null;
}

const initialState: TestSessionState = {
  testSessionId: null,
};

const testSessionSlice = createSlice({
  name: 'testSession',
  initialState,
  reducers: {
    setTestSessionId(state, action: PayloadAction<string>) {
      state.testSessionId = action.payload;
    },
    clearTestSessionId(state) {
      state.testSessionId = null;
    },
  },
});

export const { setTestSessionId, clearTestSessionId } = testSessionSlice.actions;
export default testSessionSlice.reducer;
