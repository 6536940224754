// src/services/api.ts

import axios from 'axios';
import store, { RootState } from '../store/store';

console.log({ process: process.env.REACT_APP_API_BASE_URL })
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.shotbreakdown.basketball/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Include cookies if needed
});
// Add a request interceptor to include the Authorization header
api.interceptors.request.use(
  (config) => {
    const state: RootState = store.getState();
    const token = state.auth.sessionToken;
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
