import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { enterEmail, EnterEmailResponse } from '../../../../services/auth.service';
import { setSessionToken, setUserInfo } from '../../../../store/auth.slice';
import { createTestSession } from '../../../../services/quiz.service';
import { setTestSessionId } from '../../../../store/test.session.slice';
import styles from './email.form.component.module.scss';

const EnterEmailForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Submit full name and email to /api/user/enter-email
      const data = await enterEmail(email.toLowerCase(), fullName) as EnterEmailResponse;

      // Store the session token in Redux store
      const sessionToken = data.session.access_token;
      dispatch(setSessionToken(sessionToken));
      dispatch(setUserInfo({ fullName, email }));

      // Extract the testSessionId
      const testSessionData = await createTestSession() as { testSession: { id: string } };
      const testSessionId = testSessionData.testSession.id;
      dispatch(setTestSessionId(testSessionId));

      // Redirect to /take-test/:testSessionId
      navigate(`/take-test/${testSessionId}`);
    } catch (err: any) {
      // Display error message if API request fails
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['form-group']}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">
          Start Your Shooting Assessment
          <span className={`${styles['loader']} ${loading ? '' : styles['hide']}`}></span>
        </button>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default EnterEmailForm;
