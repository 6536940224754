// src/pages/GetResults.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Markdown from "markdown-to-jsx"; // Import markdown-to-jsx for rendering markdown to TSX
import styles from "./results.page.module.scss"; // SCSS file for styling
import { getTestResults } from "../../services/quiz.service";
import Header from "../homepage/components/header/header.component";

export interface TestResult {
  test_result:string;
  test_summary: string;
}

const GetResults: React.FC = () => {
  const { testSessionId } = useParams<{ testSessionId: string }>();
  const [testResult, setTestResult] = useState<TestResult | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        if (testSessionId) {
          const data: TestResult = await getTestResults(testSessionId);
          setTestResult(data);// Set the test result data
        } else {
          setError("Test session ID is missing.");
        }
      } catch (err) {
        setError("Failed to load test results.");
      }
    };
    fetchTestResults();
  }, [testSessionId]);

  const handleShare = () => {
    if (navigator.share && testResult) {
      navigator.share({
        title: "My Shooting Test Results",
        text: "Check out my shooting test results!",
        url: window.location.href,
      })
        .then(() => console.log("Successfully shared"))
        .catch((err) => console.error("Error sharing:", err));
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!testResult) {
    return <p>Loading results...</p>;
  }

  return (
    <div className={styles['container']}>
      <Header></Header>
      <div className={styles.results}>
        {/* Convert markdown to TSX */}
        <Markdown>{testResult.test_summary}</Markdown>
      </div>
      <button className={styles.shareButton} onClick={handleShare}>
        Share Results
      </button>
    </div>
  );
};

export default GetResults;
